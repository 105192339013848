<script setup lang="ts">
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';
import { computed, inject } from 'vue';
import type { Schemas } from '#shopware';

withDefaults(
    defineProps<{
        type?: string;
    }>(),
    {
        type: 'desktop',
    },
);

const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;
const { cartItems } = useCart();
const { isLoggedIn } = useUser();

const cartItemPositionCount = computed(() => {
    return cartItems.value.reduce(
        (accumulator: number, lineItem: Schemas['LineItem']) =>
            lineItem.type === 'product' ? 1 + accumulator : accumulator,
        0,
    );
});
</script>

<template>
    <ClientOnly>
        <button
            key="addToCartClient"
            class="bg-transparent relative group py-3 px-5.5 sm:px-3.5"
            :title="$t('cart')"
            :aria-label="$t('cart')"
            @click="
                isLoggedIn
                    ? offcanvas.open('CheckoutSideCart', 'layout-header-checkout-sidecart-' + type)
                    : offcanvas.open('AccountLoginForm', 'layout-login-offcanvas')
            "
        >
            <SvgoCart class="h-6 w-auto" />
            <LazySharedBadge
                v-if="isLoggedIn"
                class="absolute bg-brand-cart top-1.25 right-1.25 !text-2xs !px-1.3 !py-0.6"
            >
                {{ cartItemPositionCount }}
            </LazySharedBadge>
            <span
                class="absolute text-sm hidden lg:group-hover:block -bottom-3 left-1/2 translate-x-[-50%] whitespace-nowrap"
            >
                {{ $t('cart') }}
            </span>
        </button>

        <SharedOffcanvasContent
            :id="'layout-header-checkout-sidecart-' + type"
            :offcanvas="offcanvas"
        />

        <template #fallback>
            <button
                key="addToCartServer"
                class="bg-transparent py-3 px-5.5 sm:px-3.5 relative group"
            >
                <SvgoCart class="h-6 w-auto" />
            </button>
        </template>
    </ClientOnly>
</template>
